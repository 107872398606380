@import url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400..700;1,400..700&family=Neuton:ital,wght@0,200;0,300;0,400;0,700;0,800;1,400&display=swap');

@font-face {
  font-family: Elios;
  src: local('Elios'), url(./fonts/Elios.otf) format('opentype');
}

* {
  margin: 0;
  padding: 0;
  font-family: "Arimo", sans-serif;
  cursor: crosshair !important;
  overscroll-behavior: none;
}

body {
  background-color: ghostwhite;
}

body {
  width: 100vw;
  height: 100vh;
}

img {
  width: 100%;
  display: block;
}

/* 13, 21, 34, 55 */

* { 
  font-size: 16px;
  line-height: calc(16px * 1.3);
}

h1 { 
  font-size: 55px;
  line-height: calc(55px * 1.3);
}

h2 { 
  font-size: 34px;
  line-height: calc(34px * 1.3);
}

h3 { 
  font-size: 21px;
  line-height: calc(21px * 1.3);
}

a {
  color: blue;
  font-family: Elios;
}

.elios {
    font-family: Elios;
}